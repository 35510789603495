<template>
  <b-card>

    <b-row>

      <!-- User Info: Left col -->
      <b-col
        cols="12"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar
            :src="userData.logo"
            :text="avatarText('M')"
            size="104px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ userData.name }}
              </h4>
              <span class="card-text">{{ userData.email }}</span>
            </div>
            <div class="d-flex flex-wrap">
              <b-button
                :to="{ name: 'test'}"
                variant="primary"
              >
                Edit
              </b-button>
              <b-button
                variant="outline-danger"
                class="ml-1"
              >
                Delete
              </b-button>
            </div>
          </div>
        </div>

        <!-- User Stats -->
        <div class="d-flex align-items-center mt-2">
          <div class="d-flex align-items-center mr-2">
            <p> {{ userData.about }}</p>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col
        cols="12"
        xl="6"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="CheckIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Street</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ userData.address.street }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="StarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">City</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ userData.address.city }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="StarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">State</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ userData.address.state }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="FlagIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Country</span>
            </th>
            <td class="pb-50">
              {{ userData.address.country }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon
                icon="PhoneIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Contact</span>
            </th>
            <td>
              {{ userData.phone_number }}
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import axios from 'axios'
import {
  BCard, BButton, BAvatar, BRow, BCol,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import router from '@/router'

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar,
  },
  data() {
    return {
      userData: [],
    }
  },
  created() {
    axios.get(`https://entities-microsevice.muslimdo.com/api/internalops/entity/${router.currentRoute.params.id}`).then(res => {
      this.userData = res.data.data
      console.log(res.data.data)
    })
  },
  setup() {
    return {
      avatarText,
    }
  },
}
</script>

<style>

</style>
